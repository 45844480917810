$(function () {
  if ($("#trading").length) {
    const TRADING = {
      iptacrTable: $("#iptacr-table").DataTable({
        columns: [
          {
            data: "datetime",
            title: "Datetime",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              let date = new Date(sData);
              let dateFormatted = `${date
                .getDate()
                .toString()
                .padStart(2, "0")}/${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")} ${date
                .getHours()
                .toString()
                .padStart(2, "0")}:${date
                .getMinutes()
                .toString()
                .padStart(2, "0")}`;
              $(nTd).html(dateFormatted);
            },
          },
          {
            data: "interval_pool_trading_alert_condition.interval_pool.pool.third_party_identifier",
            title: "Symbol",
          },
          {
            data: "interval_pool_trading_alert_condition.interval_pool.interval",
            title: "Interval",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              $(nTd).html(`${sData.number_of_units} ${sData.unit}`);
            },
          },
          {
            data: "category",
            title: "Category",
          },
          {
            data: "value",
            title: "Value",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              $(nTd).html(Math.round(sData));
            },
          },
          {
            data: "tracking",
            title: "Tracking",
          },
          {
            data: "finalised",
            title: "Finalised",
          },
          {
            data: "checked",
            title: "Checked",
          },
          {
            data: "id",
            title: "",
            searchable: false,
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).html(
                `<div class="d-flex"><a href="/admin/interval_pool_trading_alert_condition_records/${sData}/edit" target="_blank" class="btn btn-sm btn-secondary">edit</a></div>`
              );
            },
          },
        ],
        dom: '<"top"i>frtp',
        order: [[0, "desc"]],
        ordering: true,
        paging: false,
        drawCallback: function () {
          $("#iptacr-table").removeClass("gy-5");
        },
      }),
      updateIntervalPoolTradingAlertConditions: undefined,
      updateTable: undefined,
      init: async () => {
        $("#play-sound").on("click", function (e) {
          e.preventDefault();
          $("#play-sound").addClass("d-none");
          $("#bird-audio").removeClass("d-none");
        });
        TRADING.refreshTable();
        TRADING.refreshTableInterval();
        TRADING.refreshIntervalPoolTradingAlertConditions();
        TRADING.refreshIntervalPoolTradingAlertConditionsInterval();
      },
      refreshIntervalPoolTradingAlertConditions: async () => {
        try {
          let response = await $.ajax({
            url: `/interval_pool_trading_alert_condition_records`,
          });
          TRADING.iptacrTable.clear();
          TRADING.iptacrTable.rows.add(response);
          TRADING.iptacrTable.columns.adjust().draw();
          let playSound = false;
          response.forEach(function (iptacr) {
            if (!playSound && !iptacr.checked) {
              playSound = true;
            }
          });
          if (playSound && !$("#bird-audio").hasClass("d-none")) {
            $("#bird-audio audio")[0].play();
          }
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshTable: async () => {
        try {
          $.ajax({
            url: `/interval_pool_trading_alert_condition_records/trading_table`,
          });
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      refreshTableInterval: () => {
        clearInterval(TRADING.updateTable);
        TRADING.updateTable = setInterval(function () {
          TRADING.refreshTable();
        }, 60_000);
      },
      refreshIntervalPoolTradingAlertConditionsInterval: () => {
        clearInterval(TRADING.updateIntervalPoolTradingAlertConditions);
        TRADING.updateIntervalPoolTradingAlertConditions = setInterval(
          function () {
            TRADING.refreshIntervalPoolTradingAlertConditions();
          },
          60_000
        );
      },
    };
    TRADING.init();
  }
});
